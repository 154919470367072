<template>
  <div :class="name === 'list-job' ? 'd-none d-lg-flex' : 'd-flex'" class="banner-container text-white w-100 position-relative align-items-md-center shadow-lg" :style="`background: ${colour};`">
    <div class="d-flex flex-grow-1 flex-column flex-md-row justify-content-center align-items-center py-3 px-3 px-lg-0">
      <div class="d-flex align-items-center">
        <i v-if="warning" class="fa-solid fa-circle-exclamation fa-2xl mr-3"></i>
        <div class="flex flex-col mb-2 mb-md-0 mr-md-5">
          <h5 class="text-center text-md-left mb-1 mb-md-0 font-weight-bold">{{ title }}</h5>
          <p class="text-center text-md-left mb-1 mb-md-0">{{ subtitle }}</p>
        </div>
      </div>
      <div class="d-flex flex-grow justify-content-center align-items-center" v-if="useSlot">
        <slot></slot>
      </div>
      <b-button v-else-if="buttonSpinner" size="sm" pill variant="outline-light" class="px-5"><b-spinner class="my-1 mx-2" small label="Small Spinner" /></b-button>
      <b-button v-else size="sm" @click="$emit('button-click')" pill variant="outline-light" class="px-4"><p class="my-1">{{ button }}</p></b-button>
    </div>
    <span v-if="!disableClose" class="text-white close mt-2 mt-md-0 pt-1 pt-md-0 mr-3 position-absolute cursor-pointer" v-on:click="$emit('close')">
      <i class="fa-solid fa-xmark fa-2xs"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'DismissibleBanner',
  data () {
    return {
      showButtonSpinner: this.buttonSpinner
    }
  },
  watch: {
    buttonSpinner () {
      this.showButtonSpinner = this.buttonSpinner
    }
  },
  props: {
    name: String,
    title: String,
    subtitle: String,
    button: String,
    disableClose: Boolean,
    colour: String,
    buttonSpinner: Boolean,
    warning: Boolean,
    useSlot: Boolean
  }
}
</script>

<style scoped lang="scss">
.banner-container {
  min-height: 80px;
  top: 0;
  .close {
  right: 0;
  }
}
</style>

<template>
  <b-modal id="app_popup" centered ref="my-modal" size="xl" hide-footer hide-header>
    <template #default="{ close }">
      <div class="right d-flex flex-column p-2 justify-content-center align-items-center">
        <div @click="close()" class="close position-absolute cursor-pointer">
          <i class="fa-solid fa-xmark"></i>
        </div>
        <img v-lazy="require('@/assets/AnyTask.svg')" class="logo ml-lg-2 pr-lg-3 position-relative" width="149" height="50" alt="AnyTask.com"/>
        <h4 class="font-weight-bold my-2">
          {{ $t('app_popup.heading') }}
        </h4>
        <div class="my-3 text-center px-3">
          <p>
            {{ $t('app_popup.main') }}
          </p>
        </div>
        <div class="d-flex flex-grow align-items-center pl-0 h-100 py-1 mb-3">
          <a href='https://apps.apple.com/us/app/anytask-com/id6741158899' target='_blank' class="cursor-pointer">
            <img src="../../assets/AppstoreLinks/apple.svg" :style="'height: 42px;'" alt="Apple appstore link" title="Apple appstore link"/>
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.anytask.mobile' target='_blank' class="ml-2 cursor-pointer">
            <img src="../../assets/AppstoreLinks/googlePlay.png" :style="'height: 42px;'" alt="Google play store link" title="Google play store link"/>
          </a>
        </div>
        <b-button @click="close()" pill variant="outline-secondary px-5 border-0">
          {{ $t('app_popup.continue') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AppPopup',
  mounted () {
    sessionStorage.setItem('appPopupShown', true)
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .modal {
    background-color: transparent !important;
  }

  ::v-deep .modal-content {
    border-radius: 15px !important;
    overflow: clip;
    margin-inline: auto;
  }

  .close {
    right: 15px;
    top: 10px;
  }
</style>
